import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import RRenderer from "../../components/richtextRenderer";

function CookieConsentBanner() {
  const location = useLocation();
  const { locale } = useIntl();
  const { dataEn, dataFr } = useStaticQuery(graphql`
    query CookieConsentBanner {
      dataEn: contentfulCookiesConsentBanner(node_locale: { eq: "en-US" }) {
        title
        content {
          raw
        }
        acceptButtonLabel
        declineButtonLabel
      }

      dataFr: contentfulCookiesConsentBanner(node_locale: { eq: "fr" }) {
        title
        content {
          raw
        }
        acceptButtonLabel
        declineButtonLabel
      }
    }
  `);

  const data = locale === "fr" ? dataFr : dataEn;

  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams(window.location.search);
    const noCookieBar = searchParams.has("noCookieBar");

    if (noCookieBar) {
      return null;
    }
  }

  return (
    <CookieConsent
      enableDeclineButton
      disableButtonStyles
      style={{ background: "white", display: "block" }}
      contentStyle={{ marginLeft: "auto", marginRight: "auto" }}
      declineButtonText={data.declineButtonLabel}
      buttonText={data.acceptButtonLabel}
      buttonClasses="font-xs uppercase rounded-md main-button order-first"
      declineButtonClasses="font-xs uppercase rounded-md h-12 w-full lg:w-40 mt-4 lg:mt-0 lg:ml-4 border border-secondary text-secondary"
      containerClasses="border-t border-secondary p-4"
      contentClasses="max-w-78"
      buttonWrapperClasses="max-w-78 w-full flex flex-col lg:flex-row items-center mx-auto mt-6 mb-4"
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={() => initializeAndTrack(location)}
    >
      <h3 className="text-secondary">{data.title}</h3>
      <div className="mt-4">
        <RRenderer data={data.content} config={{ p: "text-text" }} />
      </div>
    </CookieConsent>
  );
}

export default CookieConsentBanner;
